import styled from '@emotion/styled';
import Radio from '@mui/material/Radio';
import FormLabel from '@mui/material/FormLabel';

const CustomRadio = styled(Radio)`
  color: black !important;
`;

const CustomFormLabel = styled(FormLabel)`
  font-size: 14px;
  font-weight: 500;
  color: black;
`;

const Container = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export { CustomRadio, CustomFormLabel, Container };
