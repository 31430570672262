import {
  Container,
  CustomRadio,
} from '@/components/Profile/SecondItem/Settings/SalesSettings/styles';
import { Controller, useForm } from 'react-hook-form';
import { RadioGroup } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import React from 'react';
import { AppSettings, Mode } from '@/types/api';
import { useRecoilState } from 'recoil';
import { appSettingState } from '@/recoil/atom';

const DeveloperMode = () => {
  const [state, setState] = useRecoilState(appSettingState);
  const { control } = useForm<Pick<AppSettings, 'mode'>>({
    values: {
      mode: state.mode,
    },
  });

  return (
    <Container>
      <div>
        <Controller
          name={'mode'}
          control={control}
          render={({ field: { value } }) => (
            <RadioGroup
              onChange={(e) =>
                setState({ ...state, mode: parseInt(e.target.value) })
              }
              value={value}
            >
              <FormControlLabel
                value={Mode.Production}
                control={<CustomRadio />}
                label="Production"
              />
              <FormControlLabel
                value={Mode.Stage}
                control={<CustomRadio />}
                label="Stage"
              />
              <FormControlLabel
                value={Mode.Development}
                control={<CustomRadio />}
                label="Development"
              />
            </RadioGroup>
          )}
        />
      </div>
    </Container>
  );
};

export default DeveloperMode;
