import { useState } from 'react';
import SvgImage from '@/components/Module/SvgImage';

const AppVersionInfo = () => {
  const [currentVersion, setCurrentVersion] = useState<string>('');
  const [serverVersion, setServerVersion] = useState<string>('');

  const haveNewVersion = currentVersion === serverVersion;

  const handleClick = () => {
    console.log('click!!!');
  };

  return (
    <div
      style={{
        display: 'flex',
        justifySelf: 'center',
        flexGrow: '1',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: '20px',
        padding: '20px',
      }}
    >
      {haveNewVersion && <SvgImage name={'start'} />}
      <div style={{ fontSize: '14px' }}>
        {haveNewVersion
          ? '새로운 업데이트가 있어요.'
          : '최신 버전을 사용 중이에요.'}
      </div>
      <div style={{ fontSize: '14px', color: '#787878', minWidth: '50px' }}>
        {`버전 ${currentVersion || '0.0.0'}`}
      </div>
      {haveNewVersion && (
        <button
          style={{
            borderRadius: '6px',
            padding: '14px',
            color: 'white',
            backgroundColor: 'black',
            width: '100%',
          }}
          onClick={handleClick}
        >{`${serverVersion || '0.0.0'}로 업데이트`}</button>
      )}
    </div>
  );
};
export default AppVersionInfo;
