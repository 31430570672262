import {
  ApprovalFilter,
  ApprovalState,
  ApprovalTempFilter,
  AppSettings,
  Mode,
  MyInfo,
  WebApprovalState,
} from '@/types/api';

export const INIT_MY_INFO: MyInfo = {
  userId: '',
  passportId: '',
  employeeNumber: '',
  name: '',
  nickname: '',
  email: '',
  positionName: '',
  positionCode: '',
  departmentName: '',
  departmentCode: '',
  divisionName: '',
  divisionCode: '',
  companyCode: '',
  companyName: '',
  organizationFullName: '',
  organizationShortName: '',
  profileImageUrl: '',
  phoneInside: '',
  phoneMobile: '',
  birthdayType: 0,
  birthday: '',
  enterDate: '',
  language: '',
  isLeader: false,
};

export const INIT_APPROVAL_STATE: ApprovalState = {
  headerKey: 0,
  myApprovalKey: 0,
  myWriteKey: 0,
  unRead: false,
};

export const INIT_WEB_APPROVAL_STATE: WebApprovalState = {
  open: true,
  keyword: '',
};

export const INIT_WEB_APPROVAL_FILTER_STATE: ApprovalFilter = {
  id: 1,
  sortType: 'authoredAt',
  types: [],
  authorPassportIds: [],
  approverPassportIds: [],
  approverStatuses: [],
  currentApproverPassportIds: [],
  coApproverPassportIds: [],
  coApproverStatuses: [],
  statuses: [],
  referrerPassportIds: [],
  authorCombinedDepartments: [],
  approverCombinedDepartments: [],
  endAuthoredAt: [],
  unreadPassportIds: [],
};

export const INIT_WEB_APPROVAL_TEMP_FILTER_STATE: ApprovalTempFilter = {
  types: [],
  authorPassportIds: [],
  approverPassportIds: [],
  currentApproverPassportIds: [],
  coApproverPassportIds: [],
};

export const INIT_APP_SETTINGS: AppSettings = {
  searchDay: 'yesterday',
  searchSeason: 'current',
  mode: Mode.Production,
};
