import { Controller, useForm } from 'react-hook-form';
import { RadioGroup } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import React from 'react';
import {
  CustomFormLabel,
  Container,
  CustomRadio,
} from '@/components/Profile/SecondItem/Settings/SalesSettings/styles';
import { useRecoilState } from 'recoil';
import { appSettingState } from '@/recoil/atom';
import { AppSettings } from '@/types/api';

const SalesSettings = () => {
  const [state, setState] = useRecoilState(appSettingState);
  const { control } = useForm<Pick<AppSettings, 'searchSeason' | 'searchDay'>>({
    values: { searchDay: state.searchDay, searchSeason: state.searchSeason },
  });

  return (
    <Container>
      <div>
        <CustomFormLabel>기본 조회 일자</CustomFormLabel>
        <Controller
          name={'searchDay'}
          control={control}
          render={({ field: { value } }) => (
            <RadioGroup
              onChange={(e) =>
                setState({ ...state, searchDay: e.target.value })
              }
              value={value}
            >
              <FormControlLabel
                value="yesterday"
                control={<CustomRadio />}
                label="어제"
              />
              <FormControlLabel
                value="today"
                control={<CustomRadio />}
                label="오늘"
              />
            </RadioGroup>
          )}
        />
      </div>
      <div>
        <CustomFormLabel>기본 조회 시즌</CustomFormLabel>
        <Controller
          name={'searchSeason'}
          control={control}
          render={({ field: { value } }) => (
            <RadioGroup
              onChange={(e) =>
                setState({ ...state, searchSeason: e.target.value })
              }
              value={value}
            >
              <FormControlLabel
                value="current"
                control={<CustomRadio />}
                label="현재 시즌"
              />
              <FormControlLabel
                value="all"
                control={<CustomRadio />}
                label="전체 시즌"
              />
            </RadioGroup>
          )}
        />
      </div>
    </Container>
  );
};

export default SalesSettings;
