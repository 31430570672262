import styled from '@emotion/styled';

const Container = styled.div`
  font-family: var(--font-Pretendard);
`;

const StyledImg = styled.img`
  width: 90px;
  height: 90px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid #e7e9ec;
`;

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  box-shadow: 0 9px 16px 0 rgba(24, 28, 50, 0.25);
  color: ${({ theme }) => theme.colors.textSecondary};
  background-color: white;
  transition: opacity 0.1s ease-in-out;
  right: 0;
  bottom: 8px;
  cursor: pointer;
  &:hover {
    opacity: 50%;
  }
`;

StyledLabel.defaultProps = {
  htmlFor: 'inputImage',
};

const StyleInput = styled.input`
  display: none;
`;

StyleInput.defaultProps = {
  id: 'inputImage',
  type: 'file',
  accept: 'image/png, image/jpeg',
};

const ProfileLayout = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 16px;
  padding: 0 20px;
`;

const StyledButton = styled.button`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledName = styled.div`
  font-size: 24px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const StyledContent = styled.div`
  font-size: 14px;
  color: #888d96;
`;

const StyledButton2 = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  padding: 14px 0;
`;

const StyledSubTitle = styled.div`
  font-size: 12px;
  color: #888d96;
`;
export {
  ProfileLayout,
  Container,
  StyledImg,
  StyledLabel,
  StyleInput,
  StyledButton,
  StyledName,
  StyledContent,
  StyledButton2,
  StyledSubTitle,
};
