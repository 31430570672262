import React, { ReactNode } from 'react';
import {
  Container,
  StyledBack,
  StyledHeader,
} from '@/components/Profile/SecondItem/Header/styles';
import Image from 'next/image';

type P = {
  children: ReactNode;
  onClose: () => void;
  width?: string;
};
const Header = ({ children, onClose, width }: P) => {
  return (
    <Container width={width}>
      <StyledHeader>
        <StyledBack onClick={onClose}>
          <Image alt={'back'} src={'/images/back.svg'} width={16} height={16} />
        </StyledBack>
      </StyledHeader>
      {children}
    </Container>
  );
};

export default Header;
