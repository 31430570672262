import { Layout } from '@/components/Profile/SecondItem/Wifi/styles';

import { ReactNode, useState } from 'react';
import SvgImage from '@/components/Module/SvgImage';
import { StyledButton2 } from '@/components/MobileMyPage/styles';
import DrawerHeader from '@/components/Profile/SecondItem/Header';
import { Drawer } from '@mui/material';
import SalesSettings from '@/components/Profile/SecondItem/Settings/SalesSettings';
import AppVersionInfo from '@/components/Profile/SecondItem/Settings/AppVersionInfo';
import DeveloperMode from '@/components/Profile/SecondItem/Settings/DeveloperMode';
import { disableMessage } from '@/utils/firebase/initialize';
import { TOKEN_KEY_SET } from '@/const/tokens';
import { removeCookie } from '@/utils/tokens';
import { useResetRecoilState } from 'recoil';
import { MyInfoState } from '@/recoil/atom';
import { useRouter } from 'next/router';

const Settings = () => {
  const [open, setOpen] = useState(false);
  const [node, setNode] = useState<ReactNode>(<SalesSettings />);
  const reset = useResetRecoilState(MyInfoState);
  const router = useRouter();

  const handleOpen = (node: ReactNode) => {
    setNode(node);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    const result = confirm('로그아웃 하시겠어요?');
    if (result) {
      await disableMessage();
      reset();
      TOKEN_KEY_SET.forEach(removeCookie);
      setTimeout(
        () =>
          router
            .push(
              `https://login.microsoftonline.com/common/oauth2/logout?post_logout_redirect_uri=${process.env.NEXT_PUBLIC_ORIGIN_URL} `,
            )
            .then((_) => console.log('로그아웃 완료')),
        500,
      );
    }
  };

  return (
    <Layout>
      <StyledButton2 onClick={() => handleOpen(<SalesSettings />)}>
        Sales 설정
        <SvgImage name={'right_arrow'} height={24} width={24} />
      </StyledButton2>
      <StyledButton2 onClick={() => handleOpen(<AppVersionInfo />)}>
        앱 버전 정보
        <SvgImage name={'right_arrow'} height={24} width={24} />
      </StyledButton2>
      <StyledButton2 onClick={() => handleOpen(<DeveloperMode />)}>
        개발모드 전환
        <SvgImage name={'right_arrow'} height={24} width={24} />
      </StyledButton2>
      <StyledButton2 onClick={handleLogout}>
        로그아웃
        <SvgImage name={'right_arrow'} height={24} width={24} />
      </StyledButton2>
      <Drawer open={open} onClose={handleClose} anchor={'right'}>
        <DrawerHeader onClose={handleClose} width={'100vw'}>
          {node}
        </DrawerHeader>
      </Drawer>
    </Layout>
  );
};
export default Settings;
