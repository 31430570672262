import { atom } from 'recoil';
import {
  ApprovalFilter,
  ApprovalState,
  ApprovalTempFilter,
  AppSettings,
  MyInfo,
  WebApprovalState,
} from '@/types/api';
import {
  INIT_APP_SETTINGS,
  INIT_APPROVAL_STATE,
  INIT_MY_INFO,
  INIT_WEB_APPROVAL_FILTER_STATE,
  INIT_WEB_APPROVAL_STATE,
  INIT_WEB_APPROVAL_TEMP_FILTER_STATE,
} from '@/const/initData';
import { recoilPersist } from 'recoil-persist';

const sessionStorage =
  typeof window !== 'undefined' ? window.sessionStorage : undefined;
const localStorage =
  typeof window !== 'undefined' ? window.localStorage : undefined;

const { persistAtom } = recoilPersist({
  key: 'localStorage',
  storage: localStorage,
});
export const MyInfoState = atom<MyInfo>({
  key: 'myInfoState',
  default: INIT_MY_INFO,
  effects_UNSTABLE: [persistAtom],
});

const { persistAtom: approvalData } = recoilPersist({
  key: 'approvalKey',
  storage: sessionStorage,
});

export const approvalState = atom<ApprovalState>({
  key: 'approvalState',
  default: INIT_APPROVAL_STATE,
  effects_UNSTABLE: [approvalData],
});

export const webApprovalState = atom<WebApprovalState>({
  key: 'webApprovalState',
  default: INIT_WEB_APPROVAL_STATE,
});

const { persistAtom: approvalFilterData } = recoilPersist({
  key: 'approvalFilterKey',
  storage: sessionStorage,
});

export const approvalFilter = atom<ApprovalFilter>({
  key: 'approvalFilterState',
  default: INIT_WEB_APPROVAL_FILTER_STATE,
  effects_UNSTABLE: [approvalFilterData],
});

export const approvalTempFilter = atom<ApprovalTempFilter>({
  key: 'approvalTempFilterState',
  default: INIT_WEB_APPROVAL_TEMP_FILTER_STATE,
});

export const swipeState = atom({ key: 'swipeState', default: true });

const { persistAtom: appSettingsData } = recoilPersist({
  key: 'appSettingKey',
  storage: localStorage,
});

export const appSettingState = atom<AppSettings>({
  key: 'appSettingState',
  default: INIT_APP_SETTINGS,
  effects_UNSTABLE: [appSettingsData],
});
